@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 0 88 93;
    --color-secondary: 208 221 209;
    --color-danger: 239 68 68;
    --color-warn: 245 158 11;
    --color-text: 0 0 0;
  }
}

@font-face {
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://cdn.flexpa.com/portal/fonts/MaisonNeue-Book.otf?v=3.19') format('woff2');
}

@font-face {
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://cdn.flexpa.com/portal/fonts/MaisonNeue-DemiBold.otf?v=3.19') format('woff2');
}

@font-face {
  font-family: 'Maison Neue';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://cdn.flexpa.com/portal/fonts/MaisonNeue-Bold.otf?v=3.19') format('woff2');
}

@font-face {
  font-family: 'Maison Neue Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://cdn.flexpa.com/portal/fonts/MaisonNeueMono-Bold.otf?v=3.19') format('woff2');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Maison Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
